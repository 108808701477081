import * as Sentry from "@sentry/remix";
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
    dsn: "https://e84d768659a87057c81b056240d2c931@o4508735196364800.ingest.us.sentry.io/4508735197937664"
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});